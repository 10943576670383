<template>
  <div class="userCentRightCens">
    <!-- <p>最近直播</p> -->
    <el-tabs v-model="tabId">
      <el-tab-pane label="最近直播" name="1"> </el-tab-pane>
    </el-tabs>
    <!-- 缩宽 -->
    <div class="userCentRightCenss">
      <div class="week-list">
        <div class="week-item" :class="same_day == item.date ? 'on' : ''" v-for="(item, index) in times" :key="index"
          @click="select(item.date)">
          <div class="day">{{ item.day }}</div>
          <div class="week">
            {{ weekEnum[item.week] ? weekEnum[item.week].slice(2) : "" }}
          </div>
        </div>
      </div>
      <div class="ov-box">
        <div class="" v-for="(item, index) in zb" :key="index" @click="check(item)">
          <div class="courseName">{{ item.classesName }}</div>
          <div class="live_item">
            <img :src="item.courseImg" class="item-img" />
            <!-- 左侧详情 -->
            <!-- <div class="content">
              <div class="zjTitle twoEllipsis">{{ item.title }}</div>
              <div class="but">立即观看</div>
            </div> -->

            <div class="content">
              <div class="zjTitle twoEllipsis">{{ item.title }}</div>
              <div class="dateBox">
                <div class="date" v-if="item.isLive == 0">
                  {{
                    `${item.startTime.substring(
                      5,
                      7
                    )}月${item.startTime.substring(
                      8,
                      10
                    )}日 ${item.startTime.substring(11, 16)}开播`
                  }}
                </div>
                <div class="date" v-else>
                  {{ `${item.startTime.substring(11, 16)}` }}
                </div>
                <div v-if="item.isLive != 0" @click.stop="gkZb(item, 1)" class="but fr">
                  {{ item.isLive == 1 ? "直播中" : "看回放" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 是否可以观看弹窗 -->
    <div class="lookBg" v-if="showLookDetail">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : "" }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getUidClassList,
  getUserCurTracking,
  getUnderwayPcAllList,
} from "@/api/user";
import Vue from "vue";
import { TimeFunction } from "@/api/TimeFunction";
let timeFunction = new TimeFunction();
import { weekEnum } from "@/api/emun";
import { courseCheckPlay } from "@/api/know";
export default {
  components: {},

  data () {
    return {
      tabId: "1",
      weekEnum: weekEnum,
      times: [],
      survey: "", // 学习概况
      name: "",
      same_day: "",
      names: "",
      zb: [],
      imageUrl: "",
      user: "",
      weekday: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      lookDeatil: null,
      showLookDetail: false,
    };
  },
  async created () {
    await this.getDate();
    this.userId = localStorage.getItem("userId");
    // 调用学习概况接口方法
    this.loadgetUserCurTracking();
    // 调用获取学生直播课信息
    this.getList();
  },
  methods: {
    //最近直播时间
    async getDate () {
      this.times = [];
      for (let i = 0; i < 7; i++) {
        timeFunction.fun_date(i).then((res) => {
          let data = {
            date: res,
            day: new Date(res).getUTCDate(),
            week: i == 0 ? 7 : Number(new Date(res).getUTCDay()),
          };
          if (i == 0) {
            this.same_day = data.date;
          }
          this.times.push(data);
        });
      }
    },
    // 学习概况接口方法
    loadgetUserCurTracking () {
      getUserCurTracking(this.userId).then((res) => {
        if (res.code == 0) {
          for (let i in res.msg) {
            let item = res.msg[i];
            if (i == "sumTime" || i == "todayTime") {
              let a = item.indexOf("小时");
              let b = item.indexOf("分");
              let data = {
                hour: 0,
                minute: 0,
              };
              if (a != -1) {
                data.hour = item.substr(0, a);
              }
              if (b != -1) {
                data.minute = item.substr(a != -1 ? a + 2 : 0, b - 3);
              }
              res.msg[i] = data;
            }
          }
          this.survey = res.msg;
        }
      });
    },
    closeLook () {
      this.showLookDetail = false;
    },
    // 调用获取学生直播课信息(点击)res.code==0
    getList () {
      getUnderwayPcAllList(this.userId, this.same_day).then((res) => {
        if (res.code == 0) {
          /* for (let item of res.msg.cla) {
            item.liveType = 1
            item.title = item.tiitle
          }
          for (let item of res.msg.live) {
            item.liveType = 2
          }
          this.zb = res.msg.cla.concat(res.msg.live) */
          this.zb = res.data;
        }
      });
    },

    select (iemw) {
      this.same_day = iemw;
      // 调用获取学生直播课信息
      this.getList();
    },
    //观看回放
    check (item) {
      /* if (item.liveType == 1) {
        if (item.productLine == 3) {
          this.$router.push(`/VIP/VIPClassxq?id=${item.id}`)
        } else {
          this.$router.push(`/typeclassxq?id=${item.id}`)
        }
      } else {
        this.$router.push(`/zhiboclassxq?id=${item.id}`)
      } */
    },
    gkZb (item) {
      if (item.isLive == 2) {
        this.$message({
          type: "warning",
          message: "回放生成中！",
        });
        return;
      }
      if (item.genre == 2) {
        this.sub = {
          classId: item.classId,
          id: item.id,
          couseId: item.id,
          userId: this.userId,
          tenantId: this.tenantId,
          ccConfig: escape(item.ccConfig),
          startTime: item.startTime,
          ccLiveid: item.ccLiveid,
          recordId: item.recordId,
          ccRomid: item.ccRomid,
          chapterName: item.courseName ? item.courseName : "",
        };
      } else {
        this.sub = {
          id: item.id,
          classId: item.classId,
          couseId: item.id,
          userId: this.userId,
          tenantId: this.tenantId,
          ccConfig: escape(item.ccConfig),
          startTime: item.startTime,
          chapterName: item.courseName ? item.courseName : "",
        };
      }

      if (item.privateClassId) {
        this.sub = {
          courseId: item.privateClassId,
          classesId: item.classId,
          sectionId: item.sectionId,
          liveStatus: item.isLive
        }
        const data = Vue.prototype.export(this.sub)
        // this.$router.push({
        //   path: `/livebofang?${data}`,
        // });
        courseCheckPlay({
          courseType: '1',
          userId: this.userId,
          chapterId: item.id,
          source: 1
        }).then((res) => {
          if (res.code == 0) {
            const routeUrl = this.$router.resolve({
              path: `/livebofangxbk?${data}`
            })
            window.open(routeUrl.href, '_blank')
          } else {
            this.lookDeatil = res.msg
            this.showLookDetail = true
          }
        })
      } else {
        const data = Vue.prototype.export(this.sub)
        // this.$router.push({
        //   path: `/livebofang?${data}`,
        // });
        courseCheckPlay({
          courseType: '1',
          userId: this.userId,
          chapterId: item.id,
          source: 1
        }).then((res) => {
          if (res.code == 0) {
            const routeUrl = this.$router.resolve({
              path: `/livebofang?${data}`
            })
            window.open(routeUrl.href, '_blank')
          } else {
            this.lookDeatil = res.msg
            this.showLookDetail = true
          }
        })
      }
    },
  },
};
</script>
<style lang="less" scoped>
.userCentRightCens {
  width: 100%;
  padding: 0px 24px 24px;
  background: #fff;
  height: auto;
  border-radius: 8px 8px 8px 8px;
  margin: auto;
  // 标题
  p {
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #27323f;
  }
  // 缩宽
  .userCentRightCenss {
    width: 100%;
    height: auto;
    margin: auto;
    // 日期
    .dayt {
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dayts {
        width: auto;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .spana {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #27323f;
          margin-bottom: 5px;
        }
        .spanb {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
      .activ {
        width: auto;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .spana {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
          margin-bottom: 5px;
        }
        .spanb {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
        }
      }
    }

    .ov-box {
      overflow-y: scroll;
      scrollbar-width: none;
      height: 348px;
      margin-top: 32px;
      .courseName {
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 25px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        // line-height: 20px;
      }
    }
  }
}
.week-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .week-item {
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    text-align: center;
    justify-content: space-between;
    padding-top: 24px;
    .day {
      width: 36px;
      height: 36px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #dddddd;
      text-align: center;
      line-height: 36px;
      margin-bottom: 8px;
      cursor: pointer;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }

    .week {
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
  }
  .on {
    .day {
      background: #4a6af0;
      box-shadow: 0px 4px 6px 1px rgba(16, 70, 175, 0.2);
      color: #fff !important;
    }
  }
}
.live_item {
  width: 312px;
  height: 104px;
  background: #f8f9fa;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-bottom: 16px;
  display: flex;
  padding: 16px;
  .item-img {
    flex-shrink: 0;
    width: 108px;
    height: 72px;
    background: #c4c4c4;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-right: 12px;
  }
  .content {
    width: 100%;
    height: 100%;
    .zjTitle {
      height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .dateBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .but {
        cursor: pointer;
        margin-top: 8px;
        float: right;
        width: 72px;
        height: 24px;
        background: #ffffff;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #4a6af0;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #4a6af0;
        line-height: 22px;
        text-align: center;
      }
      .date {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin: 0px !important;
  }
  .el-tabs__item {
    padding-top: 24px;
    padding-bottom: 15px;
    height: auto !important;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }
  .is-active {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
  .el-tabs__active-bar {
    width: 20px !important;
    height: 3px;
    background: #4a6af0;
    border-radius: 1px 1px 1px 1px;
    opacity: 1;
    margin-left: 22px;
  }
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
